import { IOption, IService } from "./src/types/api";
import { IImageProxy } from "./src/utils/image";
import { IAlgoliaProduct } from "./src/services/algolia/product";

export class PaginationDefaultConfig {
  static PRODUCTS_GRID_NUM_COLS = 4;
  static PRODUCTS_LIST_NUM_COLS = 3;
  static PRODUCTS_PAGINATION_SIZE = 7;
  static SERVICES_NUM_COLS = 3;
  static SERVICES_NUM_ROWS = 4;
  static SERVICES_PAGINATION_SIZE = 7;
}

export class ReviewConfig {
  static COUNT_MAX = 20;
  static RATING_MAX = 10;
}

export const PRODUCT_NOT_FOUND_CONTACT_DETAILS: Record<string, Record<string, string>> = {
  au: {
    AUSTRALIA: "1300 352 872",
    "CANBERRA RESIDENTS": "041 387 7085",
  },
  nz: {
    "NEW ZEALAND": "0508 454 873",
  },
  us: {
    "UNITED STATES": "1 844 454 8738",
  },
};

export const PRODUCT_SORT_OPTIONS: IOption[] = [
  { value: "", label: "Relevance" },
  { value: "price_asc", label: "Price (Low to High)" },
  { value: "price_desc", label: "Price (High to Low)" },
  { value: "name_asc", label: "Product Name (A-Z)" },
  { value: "name_desc", label: "Product Name (Z-A)" },
];

export const PRODUCT_PER_PAGE_GRID_OPTIONS: IOption[] = [
  { value: "16", label: "16 Products" },
  { value: "32", label: "32 Products" },
  { value: "48", label: "48 Products" },
];

export const PRODUCT_PER_PAGE_LIST_OPTIONS: IOption[] = [
  { value: "48", label: "48 Products" },
  { value: "96", label: "96 Products" },
  { value: "144", label: "144 Products" },
];

export const ERROR_MESSAGES = {
  UNKNOWN: "An unknown error has occurred, please contact us for more support.",
};

export const IMAGES = {
  TOOLKIT_LOGO: { type: "local", handle: "toolkit-logo-multi.svg" } as IImageProxy,
  PRODUCT_DEFAULT: { type: "local", handle: "product-default.png" } as IImageProxy,
};

export const BUILD_FOLDER = ".next-custom";
export const APP_ENV = process.env.NODE_ENV === "development" ? "development" : "production";
export const IMAGE_URL = APP_ENV === "development" ? "http://localhost:8788" : "";

export const PREFETCH_TIMEOUT = 2000;

export const IGNORED_ERRORS = [
  "The files you are trying to upload are too large",
  "Failed to fetch",
  "Load failed",
  "NetworkError when attempting to fetch resource.",
];

export const CATEGORIES_COUNT_SHOW = 12;
export const SPECIAL_WORDS = ["ikea", "ks", "jx", "obp", "cx", "api", "tv", "au", "nz", "us", "kas"];

export const SERVICE_DISPOSAL_CHARGE: Pick<IService, "id" | "name"> = {
  id: "packagingDisposal",
  name: "Packaging Disposal",
};

export const SERVICE_TRAVEL_CHARGE: Pick<IService, "id" | "name"> = {
  id: "travelCharge",
  name: "Travel Charge",
};

export const PRODUCT_EMPTY: IAlgoliaProduct = {
  id: "",
  name: "",
  brand: "",
  category: "",
  subCategory: "",
  price: 0,
  image: IMAGES.PRODUCT_DEFAULT,
};
